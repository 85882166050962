import * as React from "react"
import {Layout} from "../components/Layout";
import SEO from "../components/SEO";
import StyledText, {TextStyles} from "../components/typography";
import {NotFoundPageModel} from "../store/models/not-found";
import * as styles from "../styles/pages/not-found.module.scss"
import {IconLogoStep4} from "../components/svg";
import StrapiClient, {StrapiResponse, StrapiSingleTypes} from "../strapi-client/strapi-client";
import {MenuModel} from "../store/models/menu";
import {GetServerDataProps, GetServerDataReturn} from "gatsby";

type NotFoundPageProps = {
    serverData: {
        menu: StrapiResponse,
        page: StrapiResponse
    }
}

const NotFoundPage = (props: NotFoundPageProps) => {
    console.log('p', props)
    const notFoundPageModel: NotFoundPageModel = NotFoundPageModel.fromJSON(props.serverData.page);
    const menuModel: MenuModel = MenuModel.fromJSON(props.serverData.menu);

    return (
        <Layout menu={menuModel}>
            <SEO seo={notFoundPageModel.seo}></SEO>
            <section
                className={styles.container}
            >
                <IconLogoStep4 isWhite/>
                <div>
                    <StyledText
                        tag='h2'
                        textAlign='center'
                        variant={TextStyles.h2}
                    >{notFoundPageModel.title}</StyledText>
                    <StyledText
                        tag='p'
                        textAlign='center'
                        variant={TextStyles.h3}
                        className={styles.subtitle}>
                        {notFoundPageModel.description}
                    </StyledText>
                </div>
            </section>
        </Layout>
    )
}

export default NotFoundPage;

export async function getServerData(context: GetServerDataProps): GetServerDataReturn  {
    try {
        const locale: string = context.pageContext.locale as string;
        const strapi = new StrapiClient(locale);
        const page = await strapi.getSingleType(StrapiSingleTypes.notFoundPage);
        const menu = await strapi.getSingleType(StrapiSingleTypes.menu);

        return {
            props: {
                page,
                menu
            },
        }
    } catch (error) {
        console.log('e', error)
        return {
            status: 500,
            headers: {},
            props: {}
        }
    }
}
